import { instance } from '@/utils/http.js'

//创建检测报告接口
export const addTestReport = function (data) {
  return instance({
    url: '/api/v1/tang/create_detection',
    method: 'post',
    data
  })
}


//删除检测报告
export const delDetection = function (data) {
  return instance({
    url: '/api/v1/tang/del_detection',
    method: 'post',
    data
  })
}

//教师试卷列表
export const getTeacherList = function (params) {
  return instance({
    url: '/api/v1/tang/teacher_paper',
    method: 'get',
    params
  })
}
//检测报告科目
export const getDetectionList = function (params) {
  return instance({
    url: '/api/v1/tang/detection_subject_list',
    method: 'get',
    params
  })
}

//用户检测组卷模板列表
export const getCpSubjectTmp = function (params) {
  return instance({
    url: '/api/v1/tang/district',
    method: 'get',
    params
  })
}

//模板组卷创建用户试卷
export const createPaper = function (data) {
  return instance({
    url: '/api/v1/tang/create',
    method: 'POST',
    data
  })
}


//教师试卷组卷
export const doTeacherList = function (data) {
  return instance({
    url: '/api/v1/tang/do_two',
    method: 'post',
    data
  })
}

//整卷测试地区列表
export const getPaperDistricts = function (params) {
  return instance({
    url: '/api/v1/tang/paper_districts',
    method: 'get',
    params
  })
}

//整卷测试根据地区试卷来源
export const getSourceList = function (params) {
  return instance({
    url: '/api/v1/tang/source_lst',
    method: 'get',
    params
  })
}

//根据试卷来源组卷
export const postDoPaper = function (data) {
  return instance({
    url: '/api/v1/tang/do_paper',
    method: 'post',
    data
  })
}

//上传用户答题卡图片
export const uploadAnswerFile = function (data) {
  return instance({
    url: '/api/v1/tang/upload_answer_file',
    method: 'post',
    data
  })
}

//获取试卷选择题题号
export const getSelectNumbers = function (params) {
  return instance({
    url: '/api/v1/tang/select_numbers',
    method: 'get',
    params
  })
}

//提交学生试卷答案
export const submitAnswer = function (data) {
  return instance({
    url: '/api/v1/tang/submit_answer',
    method: 'post',
    data
  })
}

// //查看题目详情
export const viewInfo = function (params) {
  return instance({
    url: '/api/v1/tang/paper_detail',
    method: 'get',
    params
  })
}

//打印用户试卷
export const printPdf = function (params) {
  return instance({
    url: '/api/v1/tang/print_pdf',
    method: 'get',
    headers: {
      // application/msword 表示要处理为word格式
      // application/vnd.ms-excel 表示要处理为excel格式
      'Content-Type': 'application/json; charset=UTF-8',
    },
    responseType: 'blob',
    params
  })
}

//用户题集和教辅
export const getExplainReport = function (data) {
  return instance({
    url: '/api/v1/tang/check_explain_report',
    method: 'post',
    headers: {
      // application/msword 表示要处理为word格式
      // application/vnd.ms-excel 表示要处理为excel格式
      'Content-Type': 'application/json; charset=UTF-8',
    },
    responseType: 'blob',
    data
  })
}
//导出用户学情报告分析
export const getTeacherReport = function (data) {
  return instance({
    url: '/api/v1/tang/teacher_report',
    method: 'post',
    headers: {
      // application/msword 表示要处理为word格式
      // application/vnd.ms-excel 表示要处理为excel格式
      'Content-Type': 'application/json; charset=UTF-8',
    },
    responseType: 'blob',
    data
  })
}



// 导出答题卡
export const exportSheet = function (params) {
  return instance({
    url: '/api/v1/tang/print_answer_sheet',
    method: 'get',
    params
  })
}