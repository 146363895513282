<template>
  <div style="width: 1664px;
height: 938px;
font-family: Regular;
border-radius: 2px 2px 2px 2px;
opacity: 1;display: flex;">
    <div class="scan_wrap">
      <div class="btn_wrap">
        <el-button type="primary"
                   @click="uploadCard">上传</el-button>
      </div>
      <div class="main_wrap">
        <div class="left_img">
          <!-- <div class="block"
             v-if="defaultFlag">
          <el-image>
            <div slot="error"
                 class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div> -->
          <div class="demo-image__lazy"
               v-for="(item,index) in srcList"
               :key="item">

            <el-image :src="item"
                      :key="item"
                      :preview-src-list="srcList">
              <div slot="error"
                   class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="el-icon-close"
                 @click="delImg(index)">
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="scan_wrap "
         style="margin-left:24px">

      <div>

        <div class="right_form">
          <el-form ref="form"
                   :inline="true"
                   size="small"
                   :model="form"
                   label-width="80px">
            <el-form-item label="试卷id："
                          id="paper">
              <el-input v-model="this.$route.query.user_paper_id"></el-input>
            </el-form-item>
            <div>选择题</div>

            <el-form-item :label="`${item}：`"
                          v-for="(item,index) in form.selectList"
                          :key="index">
              <el-input v-model="form.chs_answer[item]"></el-input>
            </el-form-item>

          </el-form>
          <div class="bottom_btn_wrap"
               style="    margin-left: 81px;
    margin-top: 23px;">
            <el-button type="primary"
                       @click="submit">确定</el-button>
            <el-button style="margin-left:20px"
                       @click="goback">取消</el-button>
          </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>

import { uploadAnswerFile, getSelectNumbers, submitAnswer, viewInfo } from '@/api/dataAnalysis.js'
export default {
  data () {
    return {
      form: {
        selectList: [],
        chs_answer: []
      },
      srcList: [],
      fileList: [],
      images: [],
      viewInfoData: {},
      defaultFlag: true,
      defaultUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      url: '',
      showProgresss: false,
      percentage: 0,
      isUploading: false,// 是否正在上传文件
      state: 0
    }
  },
  watch: {
    'form.chs_answer': {
      handler (newVal, oldVal) {
        console.log('oldVal', oldVal)
        console.log('newVal', newVal)
      },
      immediate: true
    }
  },
  async created () {
    await getSelectNumbers({ user_paper_id: this.$route.query.user_paper_id }).then((respone) => {

      this.form.selectList = respone.data.list
      this.getInfo()
    })



  },
  mounted () {
    this.$nextTick(() => {
      this.user_paper_id = this.$route.query.user_paper_id
      // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
      // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
      // document.getElementsByClassName('view_warp')[0].style.background = 'none'
      // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
      // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
      // document.getElementsByClassName('view_warp')[0].style.overflow = 'hidden'
      // // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
      // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '110px'
      // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
      // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
      // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'
    })

  },
  methods: {
    async getInfo () {
      await viewInfo({ user_paper_id: this.$route.query.user_paper_id }).then(res => {
        this.viewInfoData = res.data.list
        this.viewInfoData.chs_answer = JSON.parse(this.viewInfoData.chs_answer)

        for (let key in this.viewInfoData.chs_answer) {
          this.form.selectList.forEach((item, index) => {
            console.log('item', item)
            console.log('key', key)
            if (item == key) {
              console.log('key', key)
              console.log('this.viewInfoData.chs_answer[key]', this.viewInfoData.chs_answer[key])
              this.form.chs_answer[key] = this.viewInfoData.chs_answer[key]
              console.log('this.form.chs_answer', this.form.chs_answer)

              this.$forceUpdate();
            }
          })
        }

        // try {
        this.srcList = JSON.parse(res.data.list.other_answer).map(item => {

          return this.$addHeader(item)
        })
        this.images = JSON.parse(res.data.list.other_answer).map(item => {

          return item
        })

        // }
        // catch {
        //   this.srcList = []
        // }

      })
    },
    // 删除图片
    delImg (index) {

      this.srcList.splice(index, 1)
      this.fileList.splice(index, 1)
      this.images.splice(index, 1)

    },
    // /** 上传学生答题卡，选择文件后提交 */
    async SubmitFiles (res) {
      var formData = new FormData();
      this.fileList.forEach((item, index) => {

        formData.append('discuss_image', item)
        formData.append('filename', 'discuss_image')
        var form = {
          filename: 'discuss_image',
        }
        formData.append('sign', this.$getMd5Form(form))
        this.defaultFlag = false


      })
      const { data } = await uploadAnswerFile(formData)
      // this.srcList.push(data.info.dir)
      this.images.push(data.info.name)
    },
    uploadCard () {
      this.$get_file({
        limit: 5,
        fileType: 'image/*',
      }).then(res => {
        this.fileList.push(res)
        this.srcList.push(window.webkitURL.createObjectURL(res))
        this.SubmitFiles()
      })
    },
    goback () {
      this.$router.go(-1)
    },
    async submit () {
      let data = {
        other_answer: JSON.stringify(this.images),
        chs_answer: {},
        user_paper_id: Number(this.$route.query.user_paper_id)
      }
      this.form.chs_answer.forEach((item, index) => {
        if (item) {

          data.chs_answer[index] = item
        }
      });

      console.log('data.other_answer', data.other_answer)

      await submitAnswer(data)
      this.$message.success('操作成功')
      this.state = 0
    },
  }
}
</script>

<style lang="scss"  scoped>
#paper {
  margin-bottom: 24px;
  ::v-deep .el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
    width: 224px;
    height: 32px;
    background: #ffffff;
    /* border-radius: 2px 2px 2px 2px; */
    opacity: 1;
    /* border: 1px solid #DBDBDB; */
  }
}
::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
  width: 78px;
  height: 32px;
  background: #ffffff;

  opacity: 1;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
::v-deep .el-button {
  width: 60px;
  height: 32px;

  border-radius: 2px 2px 2px 2px;
  opacity: 1;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #3f9eff;
  border-color: #3f9eff;
}
::v-deep .el-form-item__label {
  font-size: 14px !important;
  padding: 3px 0px 0 0;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 8px;
}
::v-deep .el-input {
  font-size: 14px;
}
.scan_wrap {
  padding: 24px;
  background: white;
  // height: 772px;
  // margin: 0 0px;
  // overflow-y: scroll;

  width: 820px;
  // height: 938px;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  // .el-image {
  //   width: 100%;
  // }
  ::v-deep .el-image__error {
    height: 400px;
  }
}
.main_wrap {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  border-radius: 2px;

  // height: 706px;
  .right_form {
    width: 590px;
    margin: 10px 0px 0px 48px;
    padding: 30px 0px 30px 30px;
    height: 540px;
    box-shadow: 1px 1px 10px #ddd;

    .el-form {
      padding-right: 30px;
      overflow: auto;
    }
  }
  .left_img {
    flex: 1;
    min-width: 200px;
    max-width: 816px;
    height: 834px;
    overflow-y: scroll;
    margin: 10px 0px 0 0;
    border-radius: 5px;
    .block {
      justify-content: center;
      height: 500px;
      display: flex;
      align-items: center;
      color: #c1c1c1;
      font-size: 28px;
    }
    .demo-image__lazy .delImage {
      cursor: pointer;
      position: absolute;
      color: white;
      background: #e0e0e0;
      opacity: 0.5;
      border-radius: 50%;
      top: 0;
      right: 13px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      ::v-deep .el-icon-close {
        display: block;
        float: right;
      }
    }
  }
  .bottom_btn_wrap {
    display: flex;
    justify-content: flex-start;
    margin-left: 160px;
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* 滚动条上的按钮 (上下箭头). */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(202, 202, 202);
    border-radius: 50px;
  }

  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
  }
  /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
</style>